/** @format */

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { range, addIndex, map, path, sum, join } from "ramda";
import { charts } from "horizon-shared-lib";

import AdaptiveWidget from "components/shared/elements/AdaptiveWidget";
import { addPreviousValue } from "utils/widgetHelper";
import { echartsColors } from "constants/colors";
import { hexToRgba } from "utils/stringUtil";
import { useTranslation } from "locales";

const { FunnelSector } = charts;

const StyledContainer = styled(AdaptiveWidget)`
  background: #fff;
  width: 100%;
  height: calc(100% - 50px);
  border-radius: 4px;
`;

const FunnelWidget = ({
  items,
  data,
  isShowProgress = true,
  headerHeight,
  showDataZoom,
  legend,
  grid,
}: {
  items?: any;
  data?: any;
  isShowProgress?: boolean;
  headerHeight?: number;
  showDataZoom?: boolean;
  legend?: any;
  grid?: any;
}) => {
  const { t: tSharedLib } = useTranslation("sharedLib");

  const dataSource: any = useMemo(() => {
    return data;
  }, [data]);

  const detail = path(["funnelResult", "seriesDetail"], dataSource);
  const events = path(["eventNames"], dataSource) || [];
  const stepNames = path(["stepNames"], dataSource) || [];

  const [itemSize, setItemSize] = useState<{ width: number; height: number }>({
    width: 1,
    height: 1,
  });

  const overall: any = useMemo(() => {
    return path(["funnelResult", "byTotal"], dataSource) || [];
  }, [dataSource]);

  useEffect(() => {}, []);

  const funnelDataOverall = addPreviousValue(
    addIndex(map)(
      (i, idx) => ({
        value: path([idx], overall) || 0,
        name: i,
      }),
      stepNames,
    ),
  );

  return (
    <StyledContainer setItemSize={setItemSize} headerHeight={headerHeight}>
      <FunnelSector
        items={funnelDataOverall}
        barHeight={itemSize.height - 110}
        t={tSharedLib}
      />
    </StyledContainer>
  );
};

export default FunnelWidget;
