import React from "react";
import { Translation } from "react-i18next";

const TranslationOut = ({
  ns,
  value,
  obj = {},
}: {
  ns: string;
  value: string;
  obj?: any;
}) => (
  <>
    <Translation ns={ns}>{(t) => <>{t(value, obj)}</>}</Translation>
  </>
);

export default TranslationOut;
