/** @format */

const env: string = "cndev";
const nodeEnv = process.env.NODE_ENV;

const intApiBase = "https://hpbp-int.hpbp.io/insights";
const intShareComponentURL = "https://shared-component-int.hpbp.io";

const envs: any = {
  int: {
    shareComponentURL: "https://shared-component-int.hpbp.io",
    apiBase: "https://hpbp-int.hpbp.io/insights",
    tenantId: "hpbp-int",
  },
  cndev: {
    shareComponentURL: "https://shared-component-dev.horizon.hp.com.cn",
    apiBase: "https://api-dev.horizon.hp.com.cn/insights",
    tenantId: "hpbp-cnnw1dev",
  },
};

export const apiBase =
  window.REACT_APP_API_BASE ||
  (envs[env] && nodeEnv === "development"
    ? envs[env].apiBase
    : process.env.REACT_APP_API_BASE);
export const clientSecret =
  window.REACT_APP_SHELL_SDK_CLIENT_SECRET ||
  process.env.REACT_APP_SHELL_SDK_CLIENT_SECRET;
export const clientKey =
  window.REACT_APP_SHELL_SDK_CLIENT_KEY ||
  process.env.REACT_APP_SHELL_SDK_CLIENT_KEY;
export const apiOauthBase =
  window.REACT_APP_SHELL_API_BASE || process.env.REACT_APP_SHELL_API_BASE;
export const oauthCallback =
  window.REACT_APP_SHELL_OAUTH_CALLBACK ||
  process.env.REACT_APP_SHELL_OAUTH_CALLBACK;
export const clientId =
  window.REACT_APP_SHELL_CLIENT_ID || process.env.REACT_APP_SHELL_CLIENT_ID;
export const eqrcodeVersion =
  window.REACT_APP_EQRCODE_VERSION || process.env.REACT_APP_EQRCODE_VERSION;
export const eqrcodeProjectId =
  window.REACT_APP_EQRCODE_PROJECT_ID ||
  process.env.REACT_APP_EQRCODE_PROJECT_ID;

export const shareComponentURL =
  window.REACT_APP_SHARE_COMPONENT_URL ||
  (envs[env] && nodeEnv === "development"
    ? envs[env].shareComponentURL
    : process.env.REACT_APP_SHARE_COMPONENT_URL);

export const helpEmail =
  window.REACT_APP_SHELL_HELP_EMAIL || process.env.REACT_APP_SHELL_HELP_EMAIL;

export const routerName = eqrcodeVersion === "true" ? "ps" : "insights";

export const userId: string | undefined | null = localStorage.getItem(
  "horizon:last-login-user",
);
