import { useParams } from "react-router-dom";
import {
  path,
  map,
  any,
  toUpper,
  sum,
  range,
  join,
  find,
  propEq,
  append,
  addIndex,
  filter,
} from "ramda";
import React, { FC, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import RetentionLine from "components/shared/charts/common/Line";
import Legend from "components/shared/charts/Legend";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "locales";
import { auid, generateNumberToLetterMap, getDateText } from "utils/stringUtil";
import { tooltip } from "components/shared/charts/Helper";

dayjs.extend(utc);

const StyledLine = styled.div`
  // height: 500px;
`;

const setTotal = (startDates: any) =>
  sum(map((startDate) => startDate.originalAmount, startDates));

const setTrend = (startDates: any, dataSource: any, dateSection: any) => {
  const total = setTotal(startDates);
  if (dateSection === "overall") {
    return addIndex(map)((onDate, idx) => {
      return total
        ? (
            (sum(
              map((startDate) => {
                return (
                  path(["retentionOnDates", idx, "amount"], startDate) || 0
                );
              }, startDates),
            ) *
              100) /
            total
          ).toFixed(2)
        : 0;
    }, dataSource.onDates);
  }
  const findStartDate: any = find(propEq(dateSection, "startDate"))(
    startDates || {},
  );

  return map(
    (retentionOnDate: any) =>
      path(["originalAmount"], findStartDate)
        ? (
            ((path(["amount"], retentionOnDate) || 0) * 100) /
            path(["originalAmount"], findStartDate)
          ).toFixed(2)
        : 0,
    path(["retentionOnDates"], findStartDate) || [],
  );
};

const setComparison = (startDates: any, dataSource: any, dateSection: any) => {
  return addIndex(map)((onDate: any, idx) => {
    return path([idx, "originalAmount"], startDates)
      ? (
          ((path(
            [idx, "retentionOnDates", dateSection, "amount"],
            startDates,
          ) || 0) *
            100) /
          path([idx, "originalAmount"], startDates)
        ).toFixed(2)
      : 0;
  }, dataSource.series);
};

interface LineProps {
  dateSection: string;
  dataType: string;
  analysis: any;
  retentionResult: any;
  height?: number;
  itemSize?: any;
}

const Line: FC<LineProps> = ({
  height = 500,
  dateSection,
  dataType,
  analysis,
  retentionResult,
  itemSize,
}) => {
  const { t: tField } = useTranslation("field");

  const params = useParams();
  const widgetId = path(["widgetId"], params);
  const [hideList, setHideList] = useState<any>([]);
  const [lineDatasource, setLineDatasource] = useState<any>([]);
  const [indicators, setIndicators] = useState<any>([]);

  const retentionFrom: any = useMemo(() => {
    return path(["retentionFrom"], analysis);
  }, [analysis]);

  const unit: any = useMemo(() => {
    return path(["unit"], analysis);
  }, [analysis]);

  const dataSource: any = useMemo(() => {
    const indicators = path(["indicators"], retentionResult) || [];
    const startDates = path(["startDates"], retentionResult) || [];
    const groupByDisplayNames =
      path(["groupByDisplayNames"], retentionResult) || [];
    // const onDates = filter(
    //   (i: any) => dayjs(i).unix() < dayjs().unix(),
    //   path(["onDates"], retentionResult) || []
    // );
    const filterIndicators = addIndex(filter)((i, idx) => idx < 50, indicators);
    return {
      series: startDates,
      indicators: filterIndicators,
      onDates: path(["onDates"], retentionResult) || [],
      groupByDisplayNames: groupByDisplayNames,
    };
  }, [retentionResult]);

  let series = path(["series"], dataSource);
  if (dataType === "trend") {
    series = addIndex(map)(
      (i, idx) => getDateText(idx, retentionFrom, unit, tField),
      path(["onDates"], dataSource),
    );
  }

  useEffect(() => {
    setIndicators(
      addIndex(map)(
        (i: any, idx) => ({
          ...i,
          name: i.indicatorName,
          key: toUpper(generateNumberToLetterMap()[idx]),
        }),
        path(["indicators"], dataSource),
      ),
    );
  }, [dataSource]);

  useEffect(() => {
    const groupByDisplayNames = path(["groupByDisplayNames"], dataSource);
    const filterIndicators = filter(
      (i: any) => !any((h) => h === i.key)(hideList),
      indicators || [],
    );

    const lineData = [];
    for (let i = 0; i < filterIndicators.length; i += 1) {
      const groupBys = path([i, "groupBys"], filterIndicators);
      for (let j = 0; j < groupBys.length; j += 1) {
        const groupByValues: any = map(
          (g) => g || "Unknow",
          path([j, "groupByValues"], groupBys),
        );

        const startDates = path([j, "startDates"], groupBys) || [];
        let data: any = [];
        if (dataType === "trend") {
          data = setTrend(startDates, dataSource, dateSection);
        }

        if (dataType === "comparison") {
          data = setComparison(startDates, dataSource, dateSection);
        }

        data = addIndex(map)(
          (d) => ({
            value: d,
            name: join(",", groupByValues),
            units: "%",
            eventName: path([i, "indicatorName"], filterIndicators),
            key: path([i, "key"], filterIndicators),
            groupBy: join(",", groupByDisplayNames),
          }),
          data,
        );

        const name = `${
          groupByValues.length > 0 ? `${join(",", groupByValues)} | ` : ""
        }${path([i, "key"], filterIndicators)} ${path(
          [i, "indicatorName"],
          filterIndicators,
        )}`;

        lineData.push({
          name: name,
          data: data,
          type: "line",
          symbol: "roundRect",
          connectNulls: true,
          smooth: false,
          showSymbol: false,
        });
      }
    }
    setLineDatasource(lineData);
  }, [indicators, hideList, dataSource, dateSection, dataType]);

  const LegendData = addIndex(map)(
    (i: any, idx) => ({
      ...i,
      onclick: (v: any) => {
        setHideList((list: []) => {
          if (any((itm) => itm === v.key)(list)) {
            return filter((l) => l !== v.key, list);
          } else {
            return append(v.key, list);
          }
        });
      },
    }),
    indicators,
  );

  return (
    <>
      <StyledLine>
        <RetentionLine
          height={height}
          legend={{ show: false }}
          itemSize={itemSize}
          data={{
            label: series,
            datasource: lineDatasource,
          }}
          grid={{ bottom: "5%", left: 20 }}
          yAxis={{
            axisLabel: {
              formatter: "{value} %",
            },
          }}
          tooltip={tooltip(path(["groupByDisplayNames"], dataSource))}
          axisLabelFormatter={(value: any) => {
            return value;
          }}
        />
      </StyledLine>
      <Legend
        hideList={hideList}
        data={LegendData}
        style={{ marginTop: "12px" }}
      ></Legend>
    </>
  );
};

export default Line;
