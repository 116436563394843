/** @format */
import styled from "styled-components";
import React, { lazy, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import ErrorBoundary from "components/shared/elements/ErrorBoundary";
import ToastNode from "components/shared/elements/Toast";
import Invitation from "./Invitation";
import Insights from "./Insights";
import { retryLoad } from "utils/retryLoad";
import * as env from "utils/constants";
import { routerName } from "utils/constants";
import Widget from "pages/Dashboards/Widget";

export const StyledNProgress = styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
`;

const Projects = lazy(() => retryLoad(import("./Projects")));
const ProjectsNode = () => {
  let eqrcodeVersion = env.eqrcodeVersion === "true";
  let eqrcodeProjectId = env.eqrcodeProjectId;
  if (!eqrcodeVersion) {
    return (
      <ErrorBoundary>
        <Projects />
      </ErrorBoundary>
    );
  } else {
    return <Navigate to={`/${routerName}/${eqrcodeProjectId}`} />;
  }
};
function App(props: any) {
  return (
    <>
      <StyledNProgress id="insights-progress"></StyledNProgress>
      <Helmet>
        <title>Horizon - Insights</title>
      </Helmet>
      {/* <button onClick={onf}>click</button> */}
      <Routes>
        <Route
          path="/*"
          element={<Navigate replace to={`/${routerName}/projects`} />}
        />
        <Route
          path={`/${routerName}/projects`}
          element={<ProjectsNode></ProjectsNode>}
        />
        <Route
          path={`/${routerName}/invitation/:invitationId`}
          element={<Invitation />}
        ></Route>
        <Route
          path={`/${routerName}/:functionName/:projectId/dashboards/:dashboardId/widget/:widgetId/:widgetType`}
          element={<Widget />}
        />
        <Route path={`/${routerName}/:projectId/*`} element={<Insights />} />
      </Routes>
      <ToastNode></ToastNode>
    </>
  );
}

export default App;
