/** @format */

import React, { FC, useEffect, useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";
import { path } from "ramda";
import INSIGHT_SDK from "insights-sdk/dist/es/main";
import { ConfigProvider } from "antd";
import zh_CN from "antd/es/locale/zh_CN";

import actions from "components/shared/actions";
import GlobalStyle from "./globalStyle";
import ContextProvider from "./ContextProvider";
import InsightsContext from "store/zustand/CreateStoreZustand";
import createInsightsStore from "store/zustand/ContextProviderZustand";
// import { clientKey, clientSecret } from "utils/constants";
import getTheme from "theme";

interface AppProps {
  apiHelper: any;
}

const App: FC<AppProps> = (props) => {
  actions.onGlobalStateChange((state: any, prev: any) => {
    if (path(["onLanguage"], state) !== path(["onLanguage"], prev)) {
      i18n.changeLanguage(path(["onLanguage"], state));
    }
  });
  const queryClient = new QueryClient({});
  const componentsTheme = getTheme("light");

  const store = useRef(createInsightsStore()).current;

  useEffect(() => {
    // INSIGHT_SDK.init({
    //   client_id: clientKey || "2edb841d-c1aa-40dd-a859-8ce5366c602d",
    //   client_secret: clientSecret,
    //   report_type: "realTime",
    //   track_location: true,
    // });
  }, []);

  let local = localStorage.getItem("locale");

  const antdConfig: any = {};

  if (local === "zh-CN") {
    antdConfig.locale = zh_CN;
  }

  if (local === "default") {
    const browserLang = navigator.language;
    if (browserLang === "zh-CN") {
      antdConfig.locale = zh_CN;
    }
  }

  return (
    <ConfigProvider
      theme={{ token: { fontFamily: "Roboto", borderRadius: 4 } }}
      {...antdConfig}
    >
      <StyleSheetManager disableCSSOMInjection>
        <ThemeProvider theme={componentsTheme}>
          <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <InsightsContext.Provider value={store}>
              <ContextProvider {...props} />
            </InsightsContext.Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </ConfigProvider>
  );
};

export default App;
