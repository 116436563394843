/** @format */

import * as R from "ramda";
import { compile } from "mathjs";

import { LooseStaticObject } from "constants/interface";

export const minWidth = 768;

export const empty = "--";

export const camelToSnack = (str: string) => {
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const snackToCamel = (s: string) => {
  return s.replace(/([-_][a-z])/gi, function ($1) {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

var isArray = function isArray(a: any) {
  return Array.isArray(a);
};

var isObject = function isObject(o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    var n: LooseStaticObject = {};

    Object.keys(o).forEach(function (k) {
      n[snackToCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i: any) {
      return keysToCamel(i);
    });
  }

  return o;
};

export const keysToSnack = (o: any) => {
  if (isObject(o)) {
    var n: LooseStaticObject = {};

    Object.keys(o).forEach(function (k) {
      n[camelToSnack(k)] = keysToSnack(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(function (i: any) {
      return keysToSnack(i);
    });
  }

  return o;
};

export const objToQuery = R.curry(function (transfer, obj) {
  return Object.keys(obj)
    .filter(function (key) {
      return !R.isNil(obj[key]);
    })
    .map(function (key) {
      return transfer(key) + "=" + obj[key];
    })
    .join("&");
});

export const genBgColor = (value: string) => {
  let tmp = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < value.length; i++) {
    tmp += value[i].charCodeAt(0).toString(16);
  }
  if (tmp.length > 6) {
    tmp = tmp.substr(-6);
  } else if (tmp.length > 3) {
    tmp = tmp.substr(-3);
  } else {
    tmp = "aquamarine";
  }
  const color = `#${tmp}`;
  return color;
};

export function stringToHslColor(
  str: string,
  saturation: number,
  lightness: number,
) {
  if (!str) {
    return "hsl(0, 0%, 93%)";
  }
  var hash = 0;
  for (var i = 0; i < (str || "").length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + saturation + "%, " + lightness + "%)";
}

export const hexToRgba = (hex: any, opacity: any) => {
  hex = hex.replace("#", "");
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);

  const rgba = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgba;
};

export const generateAbb = (str: string, length = 2) =>
  str
    ? str
        .split(" ")
        .map((word) => {
          if (word.length > 1) {
            return word.substr(0, length).toUpperCase();
          }
          return word[0].toUpperCase();
        })
        .join("")
    : "";

export const auid = (domain?: string, type?: string, name?: string) =>
  `auid_${domain}_${type}_${name}`.toLowerCase();

export const auidItem = (name: string) => auid("form", "item", name);

export const auidInput = (name: string) => auid("form", "txt", name);

export const auidLabel = (name: string) => auid("form", "lbl", name);

export const auidCheckbox = (name: string) => auid("form", "chk", name);

export const auidSwitch = (name: string) => auid("form", "switch", name);

export const auidRadio = (name: string) => auid("form", "radio", name);

export const auidDate = (name: string) => auid("form", "date", name);

export const auidSelect = (name: string) => auid("form", "dd", name);

export const auidSlider = (name: string) => auid("form", "slide", name);

export const auidOption = (name: string, index: any) =>
  auid("form", "dd", `${name}_option${index}`);

export const tableSkeleton = "--";

export const capitalize = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";

export const getLabel = (str: string, arr: any) => {
  const target: any = R.find((i: { value: any }) => i.value === str, arr);
  return target ? target.label : "";
};

export const textToLower = (text: []) => {
  const toLowerContent = R.map((i) => capitalize(R.toLower(i)), text || []);
  const content = text ? R.join(", ", toLowerContent) : tableSkeleton;
  return content;
};

export const setOptions = (
  values: [],
  valueField?: string | undefined | null,
  setFields?: (item: any) => any,
) =>
  R.map(
    (i: { displayName: string; name: string; id: string } | any) => ({
      label: i.displayName || i.name,
      value: i[valueField || "id"],
      ...(typeof setFields === "function" && setFields(i)),
    }),
    values || [],
  );

export const findNameById = (id: string, source?: any) => {
  const findItem = R.find(R.propEq(id, "id"))(source || []) || {};
  return R.path(["name"], findItem) || "";
};

export const typesExist = (type?: string, item?: any) =>
  R.any((i) => i === type)(item || []);

export const roundFun = (value: any, n: number) => {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
};

export const addIndexToDuplicateNames = (arr: string[]) => {
  const nameCounts: any = {};

  for (let i = 0; i < arr.length; i++) {
    const name = arr[i];

    if (nameCounts[name]) {
      const index = nameCounts[name] + 1;
      arr[i] = `${name}(${index})`;
      nameCounts[name] = index;
    } else {
      nameCounts[name] = 1;
    }
  }

  return arr;
};

export const getUUID = () => {
  const s = [];
  const hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ&#34";
  for (let i = 0; i < 36; i += 1) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      s[i] = "-";
    }
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1);
  const uuid = s.join("");
  return uuid;
};

export const generateNumberToLetterMap = () => {
  const obj: any = {};
  const startCharCode = "a".charCodeAt(0);

  for (let i = 0; i < 26; i++) {
    const letter = String.fromCharCode(startCharCode + i);
    obj[i] = letter;
  }

  return obj;
};

function isInfinity(value: any) {
  return !isFinite(value);
}

export const convertKeysToUpperCase = (
  obj: Record<string, any>,
): Record<string, any> => {
  const convertKey = (key: string) => key.toUpperCase();

  const convertedPairs: any = R.pipe(
    R.toPairs,
    R.map(([key, value]) => [convertKey(key), value]),
  )(obj);

  return R.fromPairs(convertedPairs);
};

function isVariableExistInFormula(formula: any, obj: any) {
  const variableRegex = /[A-Za-z]+/g;
  const variables = formula.match(variableRegex);
  if (!variables) {
    return false;
  }

  const operators = ["+", "-", "*", "/"];
  const brackets = ["(", ")"];

  for (let variable of variables) {
    if (
      operators.includes(variable) ||
      brackets.includes(variable) ||
      !Object.hasOwnProperty.call(obj, variable)
    ) {
      return false;
    }
  }

  return true;
}

export const calculateFormula = (expression: any, variables: any) => {
  const variablesToUpper = convertKeysToUpperCase(variables);
  if (!isVariableExistInFormula(expression, variablesToUpper)) {
    return 0;
  }

  try {
    const compiledExpression = compile(expression);
    const result = compiledExpression.evaluate(variablesToUpper);
    return isInfinity(result) ? 0 : result;
  } catch (error) {
    return null;
  }
};

export const deepCompareObjects = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

export const getOrdinalSuffix = (number: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const lastDigit = number % 10;
  const specialCases = [11, 12, 13];

  return suffixes[
    specialCases.includes(number) ? 0 : lastDigit <= 3 ? lastDigit : 0
  ];
};

const dateUnit: any = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
};

export const getDateText = (
  idx: number,
  retentionFrom: number,
  unit: string,
  tField: any,
) => {
  const unitString = dateUnit[unit || "DAY"];
  const retentionDay = retentionFrom + idx - 2;
  if (idx === 0) {
    return tField("initialUnit", { unit: unitString });
  }
  if (retentionDay === 1) {
    return tField("nextUnit", { unit: unitString });
  }

  return tField("theRetentionUnit", {
    retentionDay: retentionDay + 1,
    retentionDaySuffix: getOrdinalSuffix(retentionDay + 1),
    unit: unitString,
  });
};

export const formatNumberWithUnit = (value: any) => {
  const units = ["", "K", "M", "B", "T"];
  let unitIndex = 0;

  while (value >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  const formattedValue =
    value && value !== "0"
      ? parseFloat(value)
          .toFixed(2)
          .replace(/\.?0*$/, "")
      : 0;

  return formattedValue + units[unitIndex];
};

export const numberWithUnit = (value: any, unit: string) => {
  if (unit === "%") {
    return `${value} ${unit}`;
  }
  if (unit === "$") {
    return `${formatNumberWithUnit(value)}`;
  }
  return `${formatNumberWithUnit(value)}`;
};
