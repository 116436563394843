import {
  initReactI18next,
  useTranslation as usetranslation,
} from "react-i18next";
import i18n from "i18next";
import zhCN from "./models/zhCN";
import en from "./models/en";
import { keys, path } from "ramda";
interface IResource {
  [language: string]: typeof en;
}

const resource: IResource = {
  en: en,
  "zh-CN": zhCN,
};
window.addEventListener("languagechange", () => {
  i18n.changeLanguage("en");
  //i18n.changeLanguage(navigator.language);
});

export const getDefaultLanguage = () => {
  const userLanguage =
    path(["language"], navigator) || path(["userLanguage"], navigator);

  const locals: any = {
    en: "en",
    "zh-CN": "zh-CN",
  };

  let local = localStorage.getItem("locale");
  if (!local) {
    local = "en";
  }
  if (local === "default") {
    local = userLanguage === "zh-CN" ? userLanguage : "en";
  }
  // local = local.toLowerCase()

  let language = "en";
  if (locals[local]) {
    language = locals[local];
  } else {
    const contry = local.split("-")[0];
    if (locals[contry]) {
      language = locals[contry];
    }
  }
  return language;
};

const languageDetector: any = {
  type: "languageDetector",
  async: true,
  detect: (cb: any) => cb(getDefaultLanguage()),
  init: () => {},
  cacheUserLanguage: () => {},
};

const ns: any[] = keys(en);
i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: resource,
    ns: ns,
    // lng: "en",
    // lng: navigator.language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export const useTranslation = usetranslation<keyof typeof en>;

export const t = (...p: any) => i18n.t(...p);

export default i18n;
