/**
 * /* eslint-disable max-lines
 *
 * @format
 */

import * as creator from "utils/apiHelper";
import {
  EventsQueryParams,
  EventsAttributesQueryParams,
  AttributesQueryParams,
  CreateEnvironment,
  Environment,
  GetEnvironmentsParam,
  PageEnvironments,
  UpdateEnvironmentBody,
  GetUsersParam,
  PageUserInfos,
  GetInvitationParam,
  CreateInvitation,
  PageInvitations,
  CreateAttribute,
  PageAttributes,
  UpdateAttribute,
  CreateEvent,
  UpdateEvent,
  PageEvents,
  Event,
  Attribute,
  DashboardsQueryParams,
  CreateDashboard,
  CreateWidget,
  CreateFunnelWidget,
  CreateRetentionWidget,
  QueryResponse,
} from "constants/APITypes";

import * as APITypes from "constants/APITypes";
let request: any;

export const init = (token: string, apiBase: any, apiHelper: any) => {
  const { requestCreator, requestOptionsCreator } = apiHelper || creator;
  request = requestCreator(apiBase, requestOptionsCreator(token));
};
// *** api for HPBP admin starts

export const checkProject = (queryParams?: any) =>
  request({ url: `/v1/projects/exist`, queryParams });

export const createProjects = (body: any) =>
  request({ url: `/v1/projects`, body, method: "POST" });

export const fetchProjects = (queryParams?: any) =>
  request({ url: `/v1/projects`, queryParams });

export const fetchProject = (
  projectId: string | undefined,
  queryParams?: any,
) => request({ url: `/v1/projects/${projectId}`, queryParams });

export const updateProject = (projectId: any, body?: any) =>
  request({
    url: `/v1/projects/${projectId}`,
    body,
    method: "PATCH",
  });

export const deleteProject = (projectId: string) =>
  request({
    url: `/v1/projects/${projectId}`,
    method: "DELETE",
  });

export const fetchProjectUsage = (projectId: string, queryParams?: any) =>
  request({ url: `/v1/projects/${projectId}/usage`, queryParams });

export const createEnvironment = async (
  projectId: string,
  body: CreateEnvironment,
) => {
  let result: Environment;
  result = await request({
    url: `/v1/projects/${projectId}/environments`,
    method: "POST",
    body: body,
  });
  return result;
};
export const getEnvironments = async (
  projectId: string | undefined,
  query?: GetEnvironmentsParam,
) => {
  let result: PageEnvironments;
  result = await request({
    url: `/v1/projects/${projectId}/environments`,
    method: "GET",
    queryParams: query,
  });
  return result;
};
export const getEnvironment = async (
  projectId: string,
  evironmentId: string,
) => {
  let result: Environment;
  result = await request({
    url: `/v1/projects/${projectId}/environments/${evironmentId}`,
    method: "GET",
  });
  return result;
};
export const updateEnvironment = async (
  projectId: string,
  evironmentId: string,
  body: UpdateEnvironmentBody,
) => {
  let result: Environment;
  result = await request({
    url: `/v1/projects/${projectId}/environments/${evironmentId}`,
    method: "PATCH",
    body: body,
  });
  return result;
};
export const deleteEnvironment = (projectId: string, evironmentId: string) =>
  request({
    url: `/v1/projects/${projectId}/environments/${evironmentId}`,
    method: "DELETE",
  });

/* Event */
export const fetchEvents: (
  projectId: string,
  queryParams?: EventsQueryParams,
) => PageEvents = (projectId, queryParams) =>
  request({ url: `/v1/projects/${projectId}/events`, queryParams });

export const fetchEvent: (projectId: string, eventId: string) => Event = (
  projectId,
  eventId,
) => request({ url: `/v1/projects/${projectId}/events/${eventId}` });

export const createEvent = (projectId: string, body: CreateEvent) =>
  request({
    url: `/v1/projects/${projectId}/events`,
    body: body,
    method: "POST",
  });

export const updateEvent = (
  projectId: string,
  eventId: string,
  body: UpdateEvent,
) =>
  request({
    url: `/v1/projects/${projectId}/events/${eventId}`,
    method: "PATCH",
    body: body,
  });

export const deleteEvent = (projectId: string, eventId: string) =>
  request({
    url: `/v1/projects/${projectId}/events/${eventId}`,
    method: "DELETE",
  });

export const fetchEventsAttributes: (
  projectId: string,
  eventId: string,
  queryParams?: EventsAttributesQueryParams,
) => PageAttributes = (projectId, eventId, queryParams) =>
  request({
    url: `/v1/projects/${projectId}/events/${eventId}/attributes`,
    queryParams,
  });

export const createAttribute = (projectId: string, body: CreateAttribute) =>
  request({
    url: `/v1/projects/${projectId}/attributes`,
    body: body,
    method: "POST",
  });

export const fetchAttributes: (
  projectId: string,
  queryParams?: AttributesQueryParams,
) => PageAttributes = (
  projectId: string,
  queryParams?: AttributesQueryParams,
) =>
  request({
    url: `/v1/projects/${projectId}/attributes`,
    queryParams,
  });

export const fetchAttribute: (
  projectId: string,
  attributeId: string,
) => Attribute = (projectId, attributeId) =>
  request({
    url: `/v1/projects/${projectId}/attributes/${attributeId}`,
  });
export const updateAttribute: (
  projectId: string,
  attributeId: string,
  body: UpdateAttribute,
) => PageAttributes = (
  projectId: string,
  attributeId: string,
  body: UpdateAttribute,
) =>
  request({
    url: `/v1/projects/${projectId}/attributes/${attributeId}`,
    method: "PATCH",
    body: body,
  });

export const deleteAttribute = (projectId: string, attributeId: string) =>
  request({
    url: `/v1/projects/${projectId}/attributes/${attributeId}`,
    method: "DELETE",
  });
export const fetchAttributeEvents: (
  projectId: string,
  attributeId: string,
  query: { offset?: number; limit?: number },
) => PageEvents = (projectId, attributeId, query) =>
  request({
    url: `/v1/projects/${projectId}/attributes/${attributeId}/events`,
    method: "GET",
    queryParams: query,
  });
export const getUsers = async (projectId: string, query: GetUsersParam) => {
  let result: PageUserInfos;
  result = await request({
    url: `/v1/projects/${projectId}/users`,
    method: "GET",
    queryParams: query,
  });
  return result;
};

export const deleteUser = async (projectId: string, userId: String) =>
  request({
    url: `/v1/projects/${projectId}/users/${userId}`,
    method: "DELETE",
  });

export const getInvitations = async (
  projectId: string,
  query: GetInvitationParam,
) => {
  let result: PageInvitations;
  result = await request({
    url: `/v1/projects/${projectId}/invitations`,
    method: "GET",
    queryParams: query,
  });
  return result;
};

export const createInvitation = async (
  projectId: string,
  body: CreateInvitation,
) =>
  request({
    url: `/v1/projects/${projectId}/invitations`,
    method: "POST",
    body: body,
  });

export const confirmInvitation = (invitationId: string) =>
  request({
    url: `/v1/projects/invitations/${invitationId}/confirm`,
    method: "GET",
  });
export const deleteInvitation = (projectId: string, invitationId: string) =>
  request({
    url: `/v1/projects/${projectId}/invitations/${invitationId}`,
    method: "DELETE",
  });

// Dashboards

export const fetchDashboards: (
  projectId: string | undefined,
  queryParams?: DashboardsQueryParams,
) => Promise<APITypes.PageDashboards> = async (projectId, queryParams) => {
  return (await request({
    url: `/v1//projects/${projectId}/dashboards`,
    queryParams,
  })) as APITypes.PageDashboards;
};

export const fetchDashboard: (
  projectId: string | undefined,
  dashboardId: string | undefined,
) => Promise<APITypes.Dashboard> = async (projectId, dashboardId) => {
  return await request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}`,
  });
};
export const createDashboard: (
  projectId: string | undefined,
  body: CreateDashboard,
) => Promise<APITypes.Dashboard> = async (projectId, body) =>
  await request({
    url: `/v1/projects/${projectId}/dashboards`,
    method: "POST",
    body: body,
  });

export const updateDashboard: (
  projectId: string | undefined,
  dashboardId: string | undefined,
  body: APITypes.UpdateDashboard,
) => Promise<APITypes.Dashboard> = async (projectId, dashboardId, body) =>
  await request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}`,
    method: "PATCH",
    body: body,
  });

export const deleteDashboard = (
  projectId: string | undefined,
  dashboardId: string,
) =>
  request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}`,
    method: "DELETE",
  });

export const shareDashboard: (
  projectId: string | undefined,
  dashboardId: string | undefined,
  body?: APITypes.UpdateDashboard,
) => Promise<APITypes.Dashboard> = async (projectId, dashboardId, body) =>
  await request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}/key`,
    method: "POST",
    body: body,
  });

export const deleteShareDashboard: (
  projectId: string | undefined,
  dashboardId: string | undefined,
  body?: APITypes.UpdateDashboard,
) => Promise<APITypes.Dashboard> = async (projectId, dashboardId, body) =>
  await request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}/key`,
    method: "DELETE",
    body: body,
  });

export const createEventWidget: (
  projectId: string | undefined,
  body: CreateWidget,
) => Promise<APITypes.Widget> = async (projectId, body) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/event`,
    method: "POST",
    body: body,
  });
};

export const updateEventWidget = async (
  projectId: string | undefined,
  widgetId: string | undefined,
  body: APITypes.UpdateWidget,
) =>
  request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/event`,
    method: "PATCH",
    body: body,
  });

export const fetchEventWidget: (
  projectId: string,
  widgetId: string,
  queryParams?: any,
) => Promise<APITypes.Widget> = async (projectId, widgetId, queryParams) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/event`,
    queryParams,
  });
};
export const deleteEventWidget = (
  projectId: string | undefined,
  dashboardId: string | undefined,
  widgetId: string,
) =>
  request({
    url: `/v1/projects/${projectId}/dashboards/${dashboardId}/widgets/${widgetId}/event`,
    method: "DELETE",
  });

export const createFunnelWidget: (
  projectId: string | undefined,
  body: CreateFunnelWidget,
) => Promise<APITypes.Widget> = async (projectId, body) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/funnel`,
    method: "POST",
    body: body,
  });
};

export const updateFunnelWidget = async (
  projectId: string | undefined,
  widgetId: string | undefined,
  body: CreateFunnelWidget,
) =>
  request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/funnel`,
    method: "PATCH",
    body: body,
  });

export const fetchFunnelWidget: (
  projectId: string,
  widgetId: string,
  queryParams?: any,
) => Promise<APITypes.Widget> = async (projectId, widgetId, queryParams) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/funnel`,
    queryParams,
  });
};

export const createRetentionWidget: (
  projectId: string | undefined,
  body: CreateRetentionWidget,
) => Promise<APITypes.Widget> = async (projectId, body) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/retention`,
    method: "POST",
    body: body,
  });
};

export const updateRetentionWidget = async (
  projectId: string | undefined,
  widgetId: string | undefined,
  body: CreateRetentionWidget,
) =>
  request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/retention`,
    method: "PATCH",
    body: body,
  });

export const fetchRetentionWidget: (
  projectId: string,
  widgetId: string,
  queryParams?: any,
) => Promise<APITypes.Widget> = async (projectId, widgetId, queryParams) => {
  return await request({
    url: `/v1/projects/${projectId}/widgets/${widgetId}/retention`,
    queryParams,
  });
};

export const queryEventAnalysis = async (
  projectId: string | undefined,
  body: APITypes.AnalysisBody,
  signal?: any,
) => {
  let result: QueryResponse = await request({
    url: `/v1/projects/${projectId}/events/analysis`,
    method: "POST",
    body: { ...body, analysis: { fromComponent: true, ...body?.analysis } },
    signal,
  });
  return result;
};

export const queryFunnelAnalysis = async (
  projectId: string | undefined,
  body: APITypes.AnalysisBody,
  signal?: any,
) => {
  let result: QueryResponse = await request({
    url: `/v1/projects/${projectId}/funnel/analysis`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const queryRetentionAnalysis = async (
  projectId: string | undefined,
  body: APITypes.AnalysisBody,
  signal?: any,
) => {
  let result: QueryResponse = await request({
    url: `/v1/projects/${projectId}/retention/analysis`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const fetchRelativeComparison = async (
  projectId: string | undefined,
  body: APITypes.AnalysisBody,
  signal?: any,
) => {
  let result: QueryResponse = await request({
    url: `/v1/projects/${projectId}/events/compare`,
    method: "POST",
    body: body,
    signal,
  });
  return result;
};

export const queryCustomAnalysis = async (
  projectId: string | undefined,
  body: APITypes.CustomAnalysisBody,
  queryParams: any,
  signal?: any,
) => {
  let result: QueryResponse = await request({
    url: `/v1/projects/${projectId}/custom/analysis`,
    method: "POST",
    body: body,
    queryParams: queryParams,
    signal,
  });
  return result;
};
