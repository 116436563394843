/** @format */
import Analysis from "assets/menu/icons/Analysis";
import Dashboard from "assets/menu/icons/Dashboard";
import Environment from "assets/menu/icons/Environment";
import Events from "assets/menu/icons/Events";
import ProjectSetting from "assets/menu/icons/ProjectSetting";
import { useTranslation } from "locales";
import { useMemo } from "react";

import { routerName, eqrcodeVersion } from "utils/constants";

const isEQRcode =
  typeof eqrcodeVersion === "string"
    ? eqrcodeVersion === "true"
    : eqrcodeVersion;

export const useSettingMenu = (projectId?: string) => {
  const { t } = useTranslation("menu");
  return useMemo(() => {
    const uri = `/${routerName}/${projectId}/`;

    let analysisSub = [
      {
        key: 22,
        name: t("Funnel"),
        parentName: "analysis",
        uriName: "funnel",
        link: `${uri}analysis/funnel`,
      },
      {
        key: 23,
        name: t("Retention"),
        parentName: "analysis",
        uriName: "retention",
        link: `${uri}analysis/retention`,
      },
    ];

    if (isEQRcode) {
      analysisSub = [];
    }

    return [
      {
        key: 1,
        name: t("Dashboards"),
        uriName: "dashboards",
        link: `${uri}dashboards`,
        icon: Dashboard,
      },
      {
        key: 2,
        name: t("Analysis"),
        uriName: "analysis",
        link: `${uri}analysis`,
        icon: Analysis,
        child: [
          {
            key: 21,
            name: t("EventAnalysis"),
            parentName: "analysis",
            uriName: "event-analysis",
            link: `${uri}analysis/event-analysis`,
          },
          ...analysisSub,
          {
            key: 24,
            name: t("CustomQuery"),
            parentName: "analysis",
            uriName: "custom-query",
            link: `${uri}analysis/custom-query`,
          },
        ],
      },
      {
        key: 3,
        name: t("EventsManagement"),
        uriName: "events",
        link: `${uri}events`,
        icon: Events,
        child: [
          {
            key: 31,
            name: t("Events"),
            parentName: "events",
            uriName: "events",
            link: `${uri}events/events`,
          },
          {
            key: 32,
            name: t("EventAttributes"),
            parentName: "events",
            uriName: "events-attributes",
            link: `${uri}events/events-attributes`,
          },
          {
            key: 33,
            name: t("UserAttributes"),
            parentName: "events",
            uriName: "user-attributes",
            link: `${uri}events/user-attributes`,
          },
        ],
      },
      {
        key: 4,
        name: t("Environments"),
        uriName: "environments",
        link: `${uri}environments`,
        icon: Environment,
      },
      {
        key: 5,
        name: t("ProjectSettings"),
        uriName: "project-settings",
        link: `${uri}project-settings`,
        icon: ProjectSetting,
        child: [
          {
            key: 51,
            name: t("ProjectInformation"),
            parentName: "project-settings",
            uriName: "project-information",
            link: `${uri}project-settings/project-information`,
          },
        ],
      },
    ];
  }, [projectId, t]);
};
