/** @format */

import React, { FC, ReactNode, useState, useEffect, Children } from "react";
import { Tooltip } from "antd";

interface AntdTooltipProps {
  color?: string;
  overlayClassName?: string;
  // key?: string;
  placement?: any;
  overlayInnerStyle?: any;
  overlayStyle?: any;
  title?: ReactNode;
  destroyTooltipOnHide?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  arrow?: any;
  children?: ReactNode;
}

const AntdTooltip: FC<AntdTooltipProps> = (props) => {
  const {
    color = "#949494",
    placement = "top",
    title,
    open,
    arrow = true,
    //  key,
    destroyTooltipOnHide = false,
    overlayClassName,
    overlayInnerStyle = {},
    overlayStyle = {},
    onOpenChange,
    children,
  } = props;

  const restProps: any = {};

  if (onOpenChange && typeof onOpenChange === "function") {
    restProps.onOpenChange = onOpenChange;
  }

  if (typeof open === "boolean") {
    restProps.open = open;
  }

  //if (key) {
  // restProps.key = key;
  //}

  if (overlayClassName) {
    restProps.overlayClassName = overlayClassName;
  }

  return (
    <Tooltip
      title={title}
      color={color}
      destroyTooltipOnHide={destroyTooltipOnHide}
      overlayInnerStyle={overlayInnerStyle}
      overlayStyle={overlayStyle}
      placement={placement}
      arrow={arrow}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default AntdTooltip;
