/** @format */
import "./public_path";
import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import actions from "./components/shared/actions";
import reportWebVitals from "./reportWebVitals";

function render(props: any) {
  const { container } = props;
  ReactDOM.render(
    <App {...props} />,
    container
      ? container.querySelector("#root-insights")
      : document.querySelector("#root-insights"),
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({ runType: "alone" });
}

export async function bootstrap() {}

export async function mount(props: any) {
  actions.setActions(props);
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector("#root-insights")
      : document.querySelector("#root-insights"),
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
