import common from "./zhCN/common.json";
import model from "./zhCN/model.json";
import tips from "./zhCN/tips.json";
import projects from "./zhCN/projects.json";
import projectSettings from "./zhCN/projectSettings.json";
import menu from "./zhCN/menu.json";
import environments from "./zhCN/environments.json";
import dashboards from "./zhCN/dashboards.json";
import analysis from "./zhCN/analysis.json";
import events from "./zhCN/events.json";
import field from "./zhCN/field.json";
import button from "./zhCN/button.json";
import error from "./zhCN/error.json";
import placeholder from "./zhCN/placeholder.json";
import toast from "./zhCN/toast.json";
import options from "./zhCN/options.json";
import date from "./zhCN/date.json";
import sharedLib from "./zhCN/sharedLib.json";

const zhCN: any = {
  ...analysis,
  ...button,
  ...dashboards,
  ...common,
  ...model,
  ...tips,
  ...projects,
  ...events,
  ...projectSettings,
  ...menu,
  ...environments,
  ...field,
  ...error,
  ...placeholder,
  ...toast,
  ...options,
  ...date,
  ...sharedLib,
};

export default zhCN;
