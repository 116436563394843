/** @format */
import React from "react";
import { path, map, find, propEq, any, filter } from "ramda";
import styled from "styled-components";
import { sharedUtils } from "horizon-shared-lib";

import AntdTooltip from "components/shared/tooltips/AntdTooltip";
import { shareComponentURL } from "utils/constants";
import { size } from "constants/options";
import Line from "components/shared/charts/Line";
import Bar from "components/shared/charts/Bar";
import Pie from "components/shared/charts/Pie";
import Digital from "components/shared/charts/Digital";
import Table from "components/shared/charts/Table";
import ChinaMap from "components/shared/charts/ChinaMap";
import FunnelWidget from "components/shared/charts/Funnel/FunnelWidget";
import FunnelLine from "components/shared/charts/Funnel/FunnelLine";
import RetentionLine from "components/shared/charts/Retention/DashboardLine";
import RetentionTable from "components/shared/charts/Retention/DashboardTable";
import SmallIcon from "assets/dashboard/Small";
import MediumIcon from "assets/dashboard/Medium";
import LargeIcon from "assets/dashboard/Large";
import LineIcon from "assets/dashboard/Line";
import PieIcon from "assets/dashboard/Pie";
import ValueIcon from "assets/dashboard/Value";
import ColumnIcon from "assets/dashboard/Column";
import TableIcon from "assets/dashboard/Table";
import ChinaMapIcon from "assets/dashboard/ChinaMap";
import { setOptions } from "utils/stringUtil";
import * as env from "utils/constants";
import TranslationOut from "locales/TranslationOut";

const { getWidget } = sharedUtils;

let eqrcodeVersion = env.eqrcodeVersion === "true";

export const headerHeight: number = 45;

export const types: any = {
  LINE: Line,
  BAR: Bar,
  PIE: Pie,
  NUMBER: Digital,
  TABLE: Table,
  CHINA_MAP: ChinaMap,
  FUNNEL: FunnelWidget,
  FUNNEL_LINE: FunnelLine,
  FUNNEL_BAR: "",
  STACKED_BAR: "",
  RETENTION_LINE: RetentionLine,
  RETENTION_TABLE: RetentionTable,
};

const heightByWidghtType: any = {
  CHINA_MAP: {
    medium: 12,
    large: 12,
  },
  STACKED_BAR: {
    medium: 8,
    large: 12,
  },
};

interface SizeProp {
  w: number;
  h: number;
}

const calcSize = (layout: SizeProp, size: SizeProp) => layout.w === size.w; // && layout.h === size.h;

export const getSizeByCoordinate = (layout: SizeProp) => {
  let sizeName = "";
  const { small, medium, large } = size();
  if (layout) {
    if (calcSize(layout, small)) {
      sizeName = "small";
    }
    if (calcSize(layout, medium)) {
      sizeName = "medium";
    }
    if (calcSize(layout, large)) {
      sizeName = "large";
    }
  }
  return sizeName;
};

export const fullScreenCss: {} = {
  width: "100% ",
  height: "100%",
  position: "fixed",
  background: "#f2f3f5",
  left: 0,
  top: 0,
};

export const sharedDashbardUrl = (dashboard: any) => {
  return `${shareComponentURL}/${path(
    ["dashboardPath"],
    dashboard,
  )}?sign=${path(["dashboardKey"], dashboard)}`;
};

export const isExist = (data: any, id: string) => {
  const decideExist = (i: any) => id === i.value;
  let valueByDecide = id;
  if (!any(decideExist)(setOptions(path(["elements"], data) || []))) {
    valueByDecide = "";
  }
  return valueByDecide;
};

export const setWidgetHeight = (type: string) => (size: string) =>
  path([type, size], heightByWidghtType);

export const initialValues = (widget: any, dashboard: any, envData: any) => {
  const envId = isExist(envData, path(["environmentId"], dashboard));
  const widgetInfo = getWidget(path(["widget"], widget));
  return {
    ...widgetInfo,
    environmentId: envId,
    type: path(["type"], dashboard),
    dashboardId: path(["id"], dashboard),
    dashboardName: path(["name"], dashboard),
    size: getSizeByCoordinate(path(["layout"], widget)),
    numberTypes: path(["numberTypes"], widgetInfo) || [],
    compare: path(["compare"], widgetInfo) || { yoyRule: "YOY_WEEK" },
  };
};

export const addWidgetPayload = (
  values: any,
  widgets: any,
  widgetId: string,
) => {
  const findWidget: any = find(propEq(values.id, "i"))(widgets || []);
  return [
    ...widgets,
    {
      widget: { ...values, id: widgetId },
      layout: {
        ...path(["layout"], findWidget),
        ...size(setWidgetHeight(path(["chartType"], values)))[values.size],
      },
      environmentId: values.environmentId,
      widgetId: widgetId,
    },
  ];
};

export const updatePayload = (values: any, widgets: any) =>
  map((i) => {
    const widgetInfo = getWidget(path(["widget"], i));
    const widgetType = path(["widget", "widgetType"], i);

    const widget = { ...widgetInfo, ...values };
    let currentWidget: any = {};
    if (widgetType === "EVENT") {
      currentWidget.eventWidget = widget;
      currentWidget.widgetType = "EVENT";
    }
    if (widgetType === "FUNNEL") {
      currentWidget.funnelWidget = widget;
      currentWidget.widgetType = "FUNNEL";
    }

    const newWidget: any = {
      ...i,
      layout: {
        ...path(["layout"], i),
        ...size(setWidgetHeight(path(["chartType"], values)))[values.size],
      },
      widget: currentWidget,
      environmentId: values.environmentId,
      widgetId: widgetInfo.id,
    };

    return widgetInfo.id === values.id ? { ...i, ...newWidget } : i;
  }, widgets);

const addDefault = (widget: any, key: string, value: number) => {
  const apiValue = path(["layout", key], widget);
  return !apiValue ? value : apiValue;
};

export const transformLayout = (widgets: any) =>
  map((widget) => {
    const widgetInfo = getWidget(widget.widget);
    return {
      x: 0,
      y: 0,
      ...widget.layout,
      w: addDefault(widget, "w", 2),
      h: addDefault(widget, "h", 8),
      i: path(["id"], widgetInfo),
    };
  }, widgets || []);

export const transformDragStop = (widgets: any, layout: any) =>
  map((i) => {
    const id = path(["id"], getWidget(i.widget));
    const findLayout: {} | undefined = find(propEq(id, "i"))(layout || []);
    return { ...i, layout: findLayout, widgetId: id };
  }, widgets);

export const disabledForPie = (values: any, chartName?: string) =>
  (path(["analysis", "measures"], values) || []).length > 2 &&
  (chartName || path(["chartType"], values)) === "PIE";

export const disabledMap = (groupBy: any) =>
  !any((i: any) => (i || "").indexOf("province") > -1 || i === "省")(
    groupBy || [],
  );

export const filterDashboards = (dashboardOption: any, environmentId: string) =>
  filter((i: any) => environmentId === i.environmentId, dashboardOption);

const smallNode = (
  <>
    <SmallIcon />
    <div>
      <TranslationOut ns="options" value="small" />
    </div>
  </>
);

const MediumNode = (
  <>
    <MediumIcon />
    <div>
      <TranslationOut ns="options" value="medium" />
    </div>
  </>
);

const LargeNode = (
  <>
    <LargeIcon />
    <div>
      <TranslationOut ns="options" value="large" />
    </div>
  </>
);

export const widgetSizeOptions: any = (tipsT: any, values: any) => {
  const disabledForNumber = values.chartType === "NUMBER";
  const disabledDissNumber = values.chartType !== "NUMBER";
  return [
    {
      label: disabledDissNumber ? (
        <AntdTooltip title={tipsT("SizeWidget")}>{smallNode}</AntdTooltip>
      ) : (
        smallNode
      ),
      value: "small",
      disabled: disabledDissNumber,
    },
    {
      label: disabledForPie(values) ? (
        <AntdTooltip title={tipsT("SizeWidget")}>{MediumNode}</AntdTooltip>
      ) : (
        MediumNode
      ),
      value: "medium",
      disabled: disabledForPie(values),
    },
    {
      label: disabledForNumber ? (
        <AntdTooltip title={tipsT("SizeWidget")}>{LargeNode}</AntdTooltip>
      ) : (
        LargeNode
      ),
      value: "large",
      disabled: disabledForNumber,
    },
  ];
};

export const graphOptions: any = (tipsT: any, values: any) => {
  const basicOptions = [
    {
      label: (
        <AntdTooltip title={tipsT("lineChart")}>
          <LineIcon />
          <div>
            <TranslationOut ns="options" value="lineChart" />
          </div>
        </AntdTooltip>
      ),
      value: "LINE",
    },
    {
      label: (
        <AntdTooltip title={tipsT("PieChart")}>
          <PieIcon />
          <div>
            <TranslationOut ns="options" value="pieChart" />
          </div>
        </AntdTooltip>
      ),
      value: "PIE",
      disabled: (path(["analysis", "groupBys"], values) || []).length === 0,
    },
    {
      label: (
        <AntdTooltip title={tipsT("ValueChart")}>
          <ValueIcon />
          <div>
            <TranslationOut ns="options" value="value" />
          </div>
        </AntdTooltip>
      ),
      value: "NUMBER",
      disabled:
        (path(["analysis", "groupBys"], values) || []).length > 0 ||
        ((path(["analysis", "measures"], values) || []).length > 1 &&
          (path(["analysis", "formulas"], values) || []).length < 1) ||
        (path(["analysis", "formulas"], values) || []).length > 1,
    },
    {
      label: (
        <AntdTooltip title={tipsT("ColumnChart")}>
          <ColumnIcon />
          <div>
            <TranslationOut ns="options" value="barChart" />
          </div>
        </AntdTooltip>
      ),
      value: "BAR",
    },
    {
      label: (
        <AntdTooltip title={tipsT("Table")}>
          <TableIcon />
          <div>
            <TranslationOut ns="options" value="table" />
          </div>
        </AntdTooltip>
      ),
      value: "TABLE",
    },
  ];

  if (eqrcodeVersion) {
    basicOptions.push({
      label: (
        <AntdTooltip title={tipsT("ChinaMap")}>
          <ChinaMapIcon />
          <div>
            <TranslationOut ns="options" value="chinaMap" />
          </div>
        </AntdTooltip>
      ),
      value: "CHINA_MAP",
      disabled: disabledMap(path(["analysis", "groupBys"], values)),
    });
  }

  return basicOptions;
};

export const funnelChartOptions: any = (tipsT: any, values: any) => [
  {
    label: (
      <AntdTooltip title={tipsT("FunnelLineChart")}>
        <LineIcon />
        <div>
          <TranslationOut ns="options" value="lineChart" />
        </div>
      </AntdTooltip>
    ),
    value: "FUNNEL_LINE",
    disabled: false,
  },
  //   {
  //     label: (
  //       <Tooltip title={tipsT("FunnelBarChart")}>
  //         <ColumnIcon />
  //         <div>Bar Chart</div>
  //       </Tooltip>
  //     ),
  //     value: "FUNNEL_BAR",
  //   },
  {
    label: (
      <AntdTooltip title={tipsT("FunnelChart")}>
        <LineIcon />
        <div>
          <TranslationOut ns="options" value="funnelChart" />
        </div>
      </AntdTooltip>
    ),
    value: "FUNNEL",
  },
];

export const retentionChartOptions: any = (tipsT: any, values: any) => [
  {
    label: (
      <AntdTooltip title={tipsT("RetentionLine")}>
        <LineIcon />
        <div>
          <TranslationOut ns="options" value="lineChart" />
        </div>
      </AntdTooltip>
    ),
    value: "RETENTION_LINE",
    disabled: false,
  },
  {
    label: (
      <AntdTooltip title={tipsT("RetentionTable")}>
        <TableIcon />
        <div>
          <TranslationOut ns="options" value="table" />
        </div>
      </AntdTooltip>
    ),
    value: "RETENTION_TABLE",
  },
];

export const ratios = ["MOM", "YOY"];

export const displayValueOptions: any = (values: any) => {
  const analysis = path(["analysis"], values);
  const disableValue =
    (path(["groupBys"], analysis) || []).length > 0 ||
    ((path(["measures"], analysis) || []).length > 1 &&
      (path(["formulas"], analysis) || []).length < 1) ||
    (path(["formulas"], analysis) || []).length > 1;
  return [
    {
      row: [
        {
          value: "SUM",
          label: <TranslationOut ns="options" value="total" />,
          col: 6,
        },
        {
          value: "AVERAGE",
          label: <TranslationOut ns="options" value="average" />,
          col: 8,
        },
      ],
      isShow: true,
    },
    {
      row: [
        {
          value: ratios[0],
          label: <TranslationOut ns="options" value="monthOnMonth" />,
          col: 11,
          isHide: disableValue,
        },
        {
          value: ratios[1],
          label: <TranslationOut ns="options" value="yearOnYear" />,
          col: 9,
          isHide: path(["analysis", "unit"], values) === "WEEK" || disableValue,
        },
      ],
      isShow: path(["chartType"], values) === "NUMBER",
    },
  ];
};

export const timeScopeDefault: any = {
  HOUR: "YOY_DAY",
  DAY: "YOY_WEEK",
  MONTH: "YOY_YEAR",
};

export const yToYOptions: any = (options: any, values: any) =>
  filter(
    (i: any) =>
      any((timeScope) => timeScope === path(["analysis", "unit"], values))(
        i.isVisiable,
      ),
    options,
  );

export const StyledScreen = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  border: 1px solid #c9cdd4;
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #55a6f3;
  }
`;
