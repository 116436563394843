/** @format */

import React, { FC, useContext } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { useParams } from "react-router-dom";

import Menu from "./Menu";
import SelectProject from "./SelectProject";
import Collapse from "assets/menu/Collapse";
import { SIDEBAR_MODE } from "store/modules/ui";
import Context from "store/createContext";
import { path } from "ramda";
import { setMode } from "./SidebarHelper";

const StyledContainter = styled(animated.div)`
  width: 220px;
  background: #fff;
  overflow: hidden;
`;

const StyledMenuFooter = styled.div`
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCollapse = styled(animated.span)`
  cursor: pointer;
`;

interface SideBarProps {
  isStart?: boolean;
}

const SideBar: FC<SideBarProps> = ({ isStart }) => {
  const params = useParams();
  const { state, dispatch } = useContext(Context);
  const mode = path(["ui", "sidebarMode"], state);
  const pathname = path(["*"], params);

  console.log(params);
  console.log(pathname);

  const styles = useSpring({
    width: mode === "collapsed" ? 68 : 220,
    config: { duration: pathname === "dashboards" ? 0 : 150 },
  });

  const CollapseIconstyles = useSpring({
    to: {
      rotate: mode === "collapsed" ? 180 : 0,
      marginRight: mode === "collapsed" ? "25px" : "20px",
    },
    config: { duration: pathname === "dashboards" ? 0 : 150 },
  });

  return (
    <StyledContainter style={{ ...styles }}>
      <SelectProject mode={mode} />
      <Menu mode={mode} isStart={isStart} />
      <StyledMenuFooter>
        <StyledCollapse
          style={{ ...CollapseIconstyles }}
          onClick={() => {
            dispatch({
              type: SIDEBAR_MODE,
              payload: {
                sidebarMode: setMode(mode),
              },
            });
            // setTimeout(() => {
            //  window.dispatchEvent(new Event("resize"));
            // }, 0);
          }}
        >
          <Collapse />
        </StyledCollapse>
      </StyledMenuFooter>
    </StyledContainter>
  );
};

export default SideBar;
