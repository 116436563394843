/** @format */

import React, { useState, useEffect, useContext, useMemo } from "react";
import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import { range, map, filter, path } from "ramda";
import { Radio } from "antd";
import { sharedUtils, sharedOptions } from "horizon-shared-lib";

import { useTranslation } from "locales";
import * as api from "api";
import { StyledBetween } from "components/shared/common";
import Hint from "components/shared/elements/Hint";
import RefreshIcon from "assets/dashboard/Refresh";
import { auid, getLabel } from "utils/stringUtil";
import { useCacheData } from "utils/useQueryConfig";
import { processConfig } from "utils/widgetHelper";

const StyledHeader = styled.div`
  height: 43px;
  padding-bottom: 2px;
  padding: 10px;
`;

const StyledHeaderLeft = styled.div`
  padding-right: 5px;
  width: 63%;
  display: flex;
  align-items: center;
`;

const StyledHeaderRight = styled.div`
  display: flex;
`;

const StyledRefreshIcon = styled(RefreshIcon)`
  && {
    fill: #949494;
    margin-left: 10px;
    vertical-align: 0px;
    cursor: pointer;
    &:hover {
      fill: #229bff;
    }
  }
`;

const nameStyle = {
  fontSize: "16px",
};

const dateStyle = {
  color: "#949494",
  fontSize: "12px",
  marginTop: "2px",
};

const { GetDateString, formatDate, deconstructionDate } = sharedUtils;
const { timeScopeOptions } = sharedOptions;

const Header = ({
  item,
  dataUpdateAt,
  timeScope,
  setTimeScope,
  onRefresh = () => {},
}: {
  item?: any;
  dataUpdateAt: string;
  timeScope: string;
  setTimeScope: any;
  onRefresh?: (i: boolean) => void;
}) => {
  const { t } = useTranslation("dashboards");
  const { t: tOptions } = useTranslation("options");

  const projectData = useCacheData("project");
  const createdAt = path(["meta", "createdAt"], projectData);

  const { widget } = item;

  const widgetInfo = widget;

  const chartType = path(["chartType"], widgetInfo);
  const config = processConfig(path(["config"], widgetInfo));
  const unit = path(["analysis", "unit"], widgetInfo);
  let endAt = path(["analysis", "endAt"], widgetInfo);
  let startAt = path(["analysis", "startAt"], widgetInfo);
  const dynamicTime = path(["dynamicTime"], config);

  if (dynamicTime) {
    const dates = deconstructionDate(dynamicTime, createdAt);
    startAt = formatDate(dates[0]);
    endAt = formatDate(dates[1]);
  } else {
    startAt = GetDateString(startAt);
    endAt = GetDateString(endAt);
  }

  const linkStyle: any = {
    transition: "0.2s color",
  };

  return (
    <StyledHeader>
      <StyledBetween style={{ marginBottom: "3px" }}>
        <StyledHeaderLeft
          className="nonDraggable"
          data-auid={auid(
            "insights",
            "dashboard",
            `widget_${widgetInfo?.name}`,
          )}
        >
          <>
            <Hint
              content={widgetInfo?.name}
              style={{ ...nameStyle, ...linkStyle }}
            />
          </>
          {chartType === "TABLE" && (
            <Radio.Group
              style={{ marginLeft: "5px" }}
              value={timeScope}
              size="small"
              onChange={(e) => setTimeScope(e.target.value)}
            >
              <Radio.Button value="TOTAL">{tOptions("total")}</Radio.Button>
              <Radio.Button value={unit}>
                {getLabel(
                  unit,
                  map(
                    (i: any) => ({ ...i, label: tOptions(i.translateName) }),
                    timeScopeOptions,
                  ),
                )}
              </Radio.Button>
            </Radio.Group>
          )}
        </StyledHeaderLeft>

        <StyledHeaderRight></StyledHeaderRight>
      </StyledBetween>
      <div style={{ display: "flex" }}>
        <Hint
          content={`${startAt} ~ ${endAt}${
            dataUpdateAt
              ? ` | ${t("updatedAt", { dataUpdateAt: dataUpdateAt })}`
              : ""
          }`}
          overlayClassName="nonDraggable"
          style={dateStyle}
          data-auid={auid("insights", "dashboard", `widget_date`)}
        />
        <div>
          <StyledRefreshIcon
            className="nonDraggable hover-show"
            data-auid={auid("insights", "dashboard", `widget_refresh_btn`)}
            onClick={() => onRefresh(true)}
          />
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
