/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */
import * as R from "ramda";
import React, { ReactNode } from "react";
import type { SelectProps } from "antd";

import { getDateText } from "utils/stringUtil";
import TranslationOut from "locales/TranslationOut";

interface BooleanOptions {
  label: string | ReactNode;
  value: boolean;
}

export const platformOptions: SelectProps["options"] = [
  {
    label: "Windows",
    value: "WINDOWS",
  },
  {
    label: "Cloud",
    value: "CLOUD",
  },
];

export const eventsStatus: SelectProps["options"] = [
  {
    label: <TranslationOut ns="options" value="visible" />,
    value: "visible",
  },
  {
    label: <TranslationOut ns="options" value="invisible" />,
    value: "invisible",
  },
];

export const visibleOptions: BooleanOptions[] = [
  {
    label: <TranslationOut ns="options" value="visible" />,
    value: true,
  },
  {
    label: <TranslationOut ns="options" value="invisible" />,
    value: false,
  },
];

export const AlowReceivingOptions: BooleanOptions[] = [
  {
    label: <TranslationOut ns="options" value="allow" />,
    value: false,
  },
  {
    label: <TranslationOut ns="options" value="notAllow" />,
    value: true,
  },
];

export const PublicOptions: BooleanOptions[] = [
  { label: <TranslationOut ns="options" value="public" />, value: true },
  { label: <TranslationOut ns="options" value="nonPublic" />, value: false },
];

export const builtInOptions: SelectProps["options"] = [
  { label: <TranslationOut ns="options" value="builtIn" />, value: "true" },
  { label: <TranslationOut ns="options" value="customize" />, value: "false" },
];

export const builtInCheckboxOptions = [
  { text: <TranslationOut ns="options" value="builtIn" />, value: "true" },
  { text: <TranslationOut ns="options" value="customize" />, value: "false" },
];

export const attributeType: SelectProps["options"] = [
  { label: <TranslationOut ns="options" value="string" />, value: "STRING" },
  { label: <TranslationOut ns="options" value="number" />, value: "NUMBER" },
  {
    label: <TranslationOut ns="options" value="dateTime" />,
    value: "DATETIME",
  },
  { label: <TranslationOut ns="options" value="boolean" />, value: "BOOL" },
  { label: <TranslationOut ns="options" value="enumeration" />, value: "ENUM" },
];

export const environmentOptions = (t: any) => {
  return [
    { value: false, label: t("NonProductionEnvironment") },
    { value: true, label: t("ProductionEnvironment") },
  ];
};

export const displayOptions: { value: any; label: string | ReactNode }[] = [
  { value: "SUM", label: <TranslationOut ns="options" value="total" /> },
  { value: "AVERAGE", label: <TranslationOut ns="options" value="average" /> },
];

export const timeScopeOptions: {
  value: any;
  label: string | ReactNode;
  diff?: number;
}[] = [
  {
    label: <TranslationOut ns="options" value="byHour" />,
    value: "HOUR",
    diff: 30,
  },
  {
    label: <TranslationOut ns="options" value="byDay" />,
    value: "DAY",
  },
  {
    label: <TranslationOut ns="options" value="byWeek" />,
    value: "WEEK",
  },
  {
    label: <TranslationOut ns="options" value="byMonth" />,
    value: "MONTH",
  },
];

export const retentionTimeScopeOptions: {
  value: any;
  label: string | ReactNode;
  diff?: number;
}[] = [
  {
    label: <TranslationOut ns="options" value="byDay" />,
    value: "DAY",
  },
  {
    label: <TranslationOut ns="options" value="byWeek" />,
    value: "WEEK",
  },
  {
    label: <TranslationOut ns="options" value="byMonth" />,
    value: "MONTH",
  },
];

export const MoTMoRatioOptions: {
  value: any;
  label: string | ReactNode;
  timeScopeDefalut?: string;
  isVisiable?: string[];
}[] = [
  {
    label: <TranslationOut ns="options" value="lastDay" />,
    value: "YOY_DAY",
    isVisiable: ["HOUR"],
  },
  {
    label: <TranslationOut ns="options" value="lastWeek" />,
    value: "YOY_WEEK",
    isVisiable: ["HOUR", "DAY"],
  },
  {
    label: <TranslationOut ns="options" value="lastMonth" />,
    value: "YOY_MONTH",
    isVisiable: ["HOUR", "DAY"],
  },
  {
    label: <TranslationOut ns="options" value="lastYear" />,
    value: "YOY_YEAR",
    isVisiable: ["HOUR", "DAY", "MONTH"],
  },
];

export const size: any = (setHeight: any = (type: string) => {}) => ({
  small: { w: 1, h: setHeight("small") || 4 },
  medium: { w: 2, h: setHeight("medium") || 8 },
  large: { w: 4, h: setHeight("large") || 8 },
});

export const dashboardType: any = {
  DEFAULT: "Default",
  SHARED: "Shared",
  PRIVATE: "Private",
};

export const periodWindowOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  //   {
  //     label: "Today",
  //     value: "today",
  //   },
  {
    label: <TranslationOut ns="options" value="customize" />,
    value: "customize",
  },
];

export const periodWindowOptions_: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="today" />,
    value: 24 * 60 * 60,
  },
  {
    label: <TranslationOut ns="options" value="7Day" />,
    value: 24 * 60 * 60 * 7,
  },
  {
    label: <TranslationOut ns="options" value="14Day" />,
    value: 24 * 60 * 60 * 14,
  },
  {
    label: <TranslationOut ns="options" value="30Day" />,
    value: 24 * 60 * 60 * 30,
  },
];

export const calcMethodOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="numberOfUsers" />,
    value: "USER",
  },
  {
    label: <TranslationOut ns="options" value="totalTimes" />,
    value: "COUNT",
  },
];

export const customizedIndicatorOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  //   {
  //     label: "Round",
  //     value: "ROUND",
  //   },
  {
    label: <TranslationOut ns="options" value="percentage" />,
    value: "PERCENTAGE",
  },
  {
    label: <TranslationOut ns="options" value="twoDecimal" />,
    value: "TWO_DECIMAL",
  },
  //   {
  //     label: "Three Decimal",
  //     value: "THREE_DECIMAL",
  //   },
];

export const dateOptionsByTrend = (everyday: any) => {
  return [
    {
      label: <TranslationOut ns="options" value="overallTrend" />,
      value: "overall",
    },
    ...R.map(
      (i) => ({
        label: i.label,
        value: i.value,
      }),
      everyday,
    ),
  ];
};

export const dateOptionsByComparison = (
  everyday: any,
  retentionFrom: any,
  unit: string,
  tField: any,
) => {
  return [
    ...R.addIndex(R.map)(
      (i, idx) => ({
        label: getDateText(idx, retentionFrom, unit, tField),
        value: idx,
      }),
      everyday,
    ),
  ];
};

export const retentionDaysOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="3Days" />,
    value: 3,
  },
  {
    label: <TranslationOut ns="options" value="7Days" />,
    value: 7,
  },
  {
    label: <TranslationOut ns="options" value="14Days" />,
    value: 14,
  },
  {
    label: <TranslationOut ns="options" value="30Days" />,
    value: 30,
  },
];

export const retentionWeekOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="2Week" />,
    value: 2,
  },
  {
    label: <TranslationOut ns="options" value="3Week" />,
    value: 3,
  },
  {
    label: <TranslationOut ns="options" value="4Week" />,
    value: 4,
  },
  {
    label: <TranslationOut ns="options" value="8Week" />,
    value: 8,
  },
  {
    label: <TranslationOut ns="options" value="12Week" />,
    value: 12,
  },
];

export const retentionMonthOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="2Month" />,
    value: 2,
  },
  {
    label: <TranslationOut ns="options" value="3Month" />,
    value: 3,
  },
  {
    label: <TranslationOut ns="options" value="4Month" />,
    value: 4,
  },
  {
    label: <TranslationOut ns="options" value="6Month" />,
    value: 6,
  },
];

export const retentionCalculateByOptions: {
  value: any;
  label: string | ReactNode;
}[] = [
  {
    label: <TranslationOut ns="options" value="retention" />,
    value: "RETENTION",
  },
  {
    label: <TranslationOut ns="options" value="churn" />,
    value: "CHURN",
  },
];
