import React, { useEffect } from "react";
import nprogress from "nprogress";
import "nprogress/nprogress.css";

interface NProgressProps {
  setStart?: any;
}

const NProgress: React.FC<NProgressProps> = ({ setStart = () => {} }) => {
  useEffect(() => {
    nprogress.configure({
      showSpinner: false,
      parent: "#insights-progress",
      trickle: false,
    });
    nprogress.start();
    setStart(true);
    return () => {
      nprogress.done();
      setStart(false);
    };
  }, [setStart]);

  return <></>;
};

export default NProgress;
