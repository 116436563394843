import { Table } from "antd";
import { useParams } from "react-router-dom";
import {
  path,
  map,
  any,
  join,
  head,
  last,
  dropLast,
  concat,
  sum,
  addIndex,
  toUpper,
  toLower,
  filter,
} from "ramda";
import React, { useMemo, useState, useEffect, memo } from "react";
import { FC } from "react";
import styled from "styled-components";
import * as APITypes from "constants/APITypes";
import { useTranslation } from "locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import AntdTooltip from "components/shared/tooltips/AntdTooltip";
import {
  auid,
  getUUID,
  getDateText,
  generateNumberToLetterMap,
  formatNumberWithUnit,
} from "utils/stringUtil";
import Hint from "components/shared/elements/Hint";
import { echartsColors } from "constants/colors";

dayjs.extend(utc);

const StyledTableIndicator = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  margin: 0;
  column-gap: 8px;
  &::before {
    display: flex;
    content: "${(props) => props.index}";
    width: 32px;
    border-radius: 4px;
    color: #fff;
    justify-content: center;
    background: ${(props) => props.color || "#fff"};
  }
`;

const StyledContent = styled.div`
  background: #ffffff;
  padding: 10px 0px 0 0px;
  box-sizing: border-box;
  .ant-table-wrapper .ant-table {
    background: #fff;
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fff;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
  }
  .ant-table-wrapper .ant-table-tbody tr > td.ant-table-cell-fix-left {
    padding: 10px;
  }
`;

const StyledCurrentDay = styled.div`
  padding: 12px 16px;
`;

interface RetentionTableProps {
  analysis: any;
  retentionResult: any;
  itemSize?: any;
  pagination?: any;
}

const RetentionTable: FC<RetentionTableProps> = memo(
  ({ analysis, pagination = false, retentionResult, itemSize }) => {
    const params = useParams();
    const { t } = useTranslation("analysis");
    const { t: tField } = useTranslation("field");
    const { t: tCommon } = useTranslation("common");

    const widgetId = path(["widgetId"], params);
    const [datasource, setDatasource] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const retentionFrom: any = useMemo(() => {
      return path(["retentionFrom"], analysis);
    }, [analysis]);

    const unit: any = useMemo(() => {
      return path(["unit"], analysis);
    }, [analysis]);

    const calculateBy: any = useMemo(() => {
      return path(["calculateBy"], analysis);
    }, [analysis]);

    const detailResult: any = useMemo(() => {
      const onDates = filter(
        (i: any) => dayjs(i).unix() < dayjs().unix(),
        path(["onDates"], retentionResult) || [],
      );
      return {
        ...retentionResult,
        onDates: path(["onDates"], retentionResult) || [],
      };
    }, [retentionResult]);

    useEffect(() => {
      const dateTitle = path(["onDates"], detailResult) || [];
      const startDates = path(["startDates"], detailResult) || [];

      const groupByDisplayNames =
        path(["groupByDisplayNames"], detailResult) || [];
      const fixColumns: any = [];

      fixColumns.push({
        title: tField("indicatorDate"),
        fixed: true,
        align: "center",
        dataIndex: `name`,
        width: 200,
        render(value: any, record: any) {
          return (
            <StyledTableIndicator color={record.color} index={record.key}>
              <Hint content={value} />
            </StyledTableIndicator>
          );
        },
      });

      addIndex(map)((i, idx) => {
        fixColumns.push({
          title: i,
          align: "center",
          dataIndex: `group_${idx}`,
          width: 180,
          render(value: any, record: any) {
            return (
              <>
                <Hint content={value} />
              </>
            );
          },
        });
      }, groupByDisplayNames);

      fixColumns.push({
        title: tField("initialHeadcount"),
        align: "center",
        dataIndex: `total`,
        sorter: (a: any, b: any) => a.total - b.total,
        width: 180,
        render: (value: any) => {
          return (
            <StyledCurrentDay style={{ background: "#fff" }}>
              {formatNumberWithUnit(value)}
            </StyledCurrentDay>
          );
        },
      });

      addIndex(map)((i: any, idx) => {
        const dateColumn = {
          title: getDateText(idx, retentionFrom, unit, tField),
          dataIndex: `day_${idx}`,
          align: "center",
          width: "max-content",
          render(value: any, record: any) {
            let totalRate = "0%";
            const type = path(["type"], record);
            const parentName = path(["parentName"], record);
            const name = path(["name"], record);
            const total = path(["total"], record);
            const currentDate: string = path([`day_${idx}_date`], record);
            const retentionOnDates: any =
              path([`retention_on_dates_${idx}`], record) || [];
            const endDate = last(
              dropLast(startDates.length - retentionOnDates.length, startDates),
            );
            const betweenDate = tCommon("betweenDate", {
              start: head(startDates),
              end: endDate,
            });
            if (total > 0) {
              totalRate = `${((value * 100) / total).toFixed(2)}%`;
            }

            let subTips = t("RetentionTableValueTips", {
              total: total,
              parentName: parentName,
              date: name,
              value: value,
              dateText: toLower(getDateText(idx, retentionFrom, unit, tField)),
              currentDate: currentDate,
              totalRate: totalRate,
            });

            if (calculateBy === "CHURN") {
              subTips = t("RetentionTableValueChurnTips", {
                total: total,
                parentName: parentName,
                date: name,
                value: value,
                dateText: toLower(
                  getDateText(idx, retentionFrom, unit, tField),
                ),
                currentDate: currentDate,
                totalRate: totalRate,
              });
            }

            let tips = t("RetentionTableTotalTips", {
              dateText: toLower(getDateText(idx, retentionFrom, unit, tField)),
              betweenDate: betweenDate,
              totalRate: totalRate,
            });

            if (calculateBy === "CHURN") {
              tips = t("RetentionTableTotalChurnTips", {
                dateText: toLower(
                  getDateText(idx, retentionFrom, unit, tField),
                ),
                betweenDate: betweenDate,
                totalRate: totalRate,
              });
            }

            return (
              <>
                {value || value === 0 ? (
                  <StyledCurrentDay>
                    <AntdTooltip
                      placement="top"
                      arrow={{ pointAtCenter: true }}
                      overlayStyle={{ minWidth: "400px" }}
                      title={type === "sub" ? subTips : tips}
                    >
                      {type === "sub" && (
                        <div style={{ marginLeft: "5px" }}>
                          {formatNumberWithUnit(value)}
                        </div>
                      )}
                      <div
                        style={{
                          color: type === "sub" ? "#949494" : "#000000e0",
                        }}
                      >
                        {totalRate}
                      </div>
                    </AntdTooltip>
                  </StyledCurrentDay>
                ) : (
                  <StyledCurrentDay
                    style={{
                      background: "#fafafa",
                      height: "100%",
                      position: "absolute",
                      width: "100%",
                      padding: 0,
                      top: 0,
                    }}
                  />
                )}
              </>
            );
          },
        };

        fixColumns.push(dateColumn);
      }, dateTitle);

      setColumns([...fixColumns]);
    }, [detailResult, retentionFrom, unit, t, tField, tCommon, calculateBy]);

    useEffect(() => {
      const indicators: any = path(["indicators"], detailResult) || [];
      const onDates: any = path(["onDates"], detailResult) || [];
      const data: any[] = [];

      for (let i = 0; i < indicators.length; i += 1) {
        const groupBys = path([i, "groupBys"], indicators);
        const indicatorName = path([i, "indicatorName"], indicators);
        for (let j = 0; j < groupBys.length; j += 1) {
          const startDates = path([j, "startDates"], groupBys);
          const onDatesValueTotal: any = {};
          const groupByValues: any = path([j, "groupByValues"], groupBys);
          const children: any[] = [];

          const groupByKeys: any = {};
          addIndex(map)((o, idx) => {
            groupByKeys[`group_${idx}`] = o || tCommon("unknow");
          }, groupByValues);

          map((startDate) => {
            const retentionOnDates = path(["retentionOnDates"], startDate);
            const onDatesValue: any = {};
            addIndex(map)((o: any, idx) => {
              if (o) {
                onDatesValue[`day_${idx}`] = o.amount;
                onDatesValue[`day_${idx}_date`] = o.onDate;
              }
            }, retentionOnDates);

            children.push({
              id: getUUID(),
              parentName: indicatorName,
              name: startDate.startDate,
              total: startDate.originalAmount,
              type: "sub",
              ...groupByKeys,
              ...onDatesValue,
            });
          }, startDates);

          addIndex(map)((o, idx) => {
            const alldayValues = map((c) => c[`day_${idx}`] || 0, children);
            onDatesValueTotal[`day_${idx}`] = sum(alldayValues);
            onDatesValueTotal[`day_${idx}_date`] = o;
            const removeNull = filter(
              (r: any) => r,
              map(
                (startDate) =>
                  path(["retentionOnDates", idx, "onDate"], startDate),
                startDates,
              ),
            );

            onDatesValueTotal[`retention_on_dates_${idx}`] = removeNull;
          }, onDates);

          const color = echartsColors[i % echartsColors.length];

          data.push({
            id: getUUID(),
            key: toUpper(generateNumberToLetterMap()[i]),
            color: color,
            name: indicatorName,
            total: sum(
              map(
                (startDate: any) => path(["originalAmount"], startDate),
                path([j, "startDates"], groupBys),
              ),
            ),
            children: children,
            ...groupByKeys,
            ...onDatesValueTotal,
          });
        }
      }

      setDatasource(data);
    }, [detailResult, tCommon]);

    const expandedChildrenRowKeys = useMemo(() => {
      return datasource.filter((item) => item.children).map((item) => item.id);
    }, [datasource]);

    useEffect(() => {
      setExpandedRowKeys(expandedChildrenRowKeys);
    }, [expandedChildrenRowKeys]);

    const onExpand = (r: any, e: any) => {
      if (e.children) {
        let ids = expandedRowKeys;
        if (r) {
          ids = concat(ids, [e.id]);
        } else {
          ids = filter((i) => e.id !== i, ids);
        }
        setExpandedRowKeys(ids);
      }
    };

    const emptyDataX =
      (path(["onDates"], detailResult) || []).length * 180 +
      (path(["groupByDisplayNames"], detailResult) || []).length * 200 +
      300;
    const scrollAttr: any = {};

    if (itemSize) {
      scrollAttr.y = itemSize.height - 66;
    }

    return (
      <StyledContent>
        <Table
          columns={columns}
          dataSource={datasource}
          pagination={pagination}
          expandable={{
            expandedRowKeys: expandedRowKeys,
            defaultExpandAllRows: true,
            onExpand: onExpand,
          }}
          rowKey={(record: any) => path(["id"], record)}
          scroll={{ x: emptyDataX, ...scrollAttr }}
          virtual={true}
        />
      </StyledContent>
    );
  },
);

export default RetentionTable;
