/** @format */

import React, { FC, Suspense, lazy } from "react";
import styled from "styled-components";
import * as R from "ramda";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";

import AutoResizeNode from "components/shared/elements/AutoResizeNode";
import Container from "components/shared/elements/Container";
import Hint from "components/shared/elements/Hint";
import NProgress from "components/shared/elements/NProgress";
import { StyledTabs } from "components/shared/common";
import { useTranslation } from "locales";
import { routerName } from "utils/constants";

const Basic = lazy(() => import("./ProjectSettings/Basic"));
const MemberManagement = lazy(
  () => import("./ProjectSettings/MemberManagement"),
);

const StyledContent = styled.div`
  min-width: 950px;
  padding: 16px 24px 0;
`;

const StyledAutoResizeNode = styled(AutoResizeNode)`
  background: #ffffff;
`;

interface AnalysisProps {}

const Analysis: FC<AnalysisProps> = () => {
  const { t } = useTranslation("projectSettings");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const projectId = R.path(["projectId"], params);

  const Navigation = [
    {
      name: t("ProjectSettingsNavigation"),
      link: "",
    },
    {
      name: t("ProjectInformationNavigation"),
      link: "",
    },
  ];

  const onChange = (key: string) => {
    navigate(key);
  };

  return (
    <Container Navigation={Navigation}>
      <StyledAutoResizeNode diff={20}>
        <StyledContent>
          <div style={{ width: "100%" }}>
            <Hint />
          </div>
          <Routes>
            <Route
              path={`/`}
              element={
                <Navigate
                  replace
                  to={`/${routerName}/${projectId}/project-settings/project-information/basic`}
                />
              }
            />
          </Routes>
          <StyledTabs
            defaultActiveKey={location.pathname}
            onChange={onChange}
            items={[
              {
                label: t("BasicInformationLabel"),
                key: `/${routerName}/${projectId}/project-settings/project-information/basic`,
                children: (
                  <AutoResizeNode diff={50}>
                    <Routes>
                      <Route
                        path="/basic"
                        element={
                          <Suspense fallback={<NProgress />}>
                            <Basic />
                          </Suspense>
                        }
                      />
                    </Routes>
                  </AutoResizeNode>
                ),
              },
              {
                label: t("MemberManagementLabel"),
                key: `/${routerName}/${projectId}/project-settings/project-information/member-management`,
                children: (
                  <AutoResizeNode diff={50}>
                    <Routes>
                      <Route
                        path="/member-management"
                        element={
                          <Suspense fallback={<NProgress />}>
                            <MemberManagement />
                          </Suspense>
                        }
                      />
                    </Routes>
                  </AutoResizeNode>
                ),
              },
            ]}
          />
        </StyledContent>
      </StyledAutoResizeNode>
    </Container>
  );
};

export default Analysis;
