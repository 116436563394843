import common from "./en/common.json";
import model from "./en/model.json";
import tips from "./en/tips.json";
import projects from "./en/projects.json";
import projectSettings from "./en/projectSettings.json";
import menu from "./en/menu.json";
import environments from "./en/environments.json";
import dashboards from "./en/dashboards.json";
import analysis from "./en/analysis.json";
import events from "./en/events.json";
import field from "./en/field.json";
import button from "./en/button.json";
import error from "./en/error.json";
import placeholder from "./en/placeholder.json";
import toast from "./en/toast.json";
import options from "./en/options.json";
import date from "./en/date.json";
import sharedLib from "./en/sharedLib.json";

const en: any = {
  ...analysis,
  ...button,
  ...dashboards,
  ...common,
  ...model,
  ...tips,
  ...projects,
  ...events,
  ...projectSettings,
  ...menu,
  ...environments,
  ...field,
  ...error,
  ...placeholder,
  ...toast,
  ...options,
  ...date,
  ...sharedLib,
};

export default en;
